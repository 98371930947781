import React from "react";
import Layout from "../../layouts";
import Seo from "../../components/Seo";
import IntroBanner from "../../components/IntroBanner";
import QATeam from "../../images/news11.png";
import { graphql } from "gatsby";

const SMSBot = (props) => {
    return (
        <Layout bodyClass="page-blog">
            <Seo
                title="iTelaSoft levels up software quality with ISTQB Platinum Partnership"
                description="iTelaSoft solidifies its status as a leader in the industry by achieving one of the highest tiers in ISTQB partnership to improve customer success and outcomes."
                image={QATeam}
            />
            <IntroBanner
                title="iTelaSoft levels up software quality with ISTQB Platinum Partnership"
                image={QATeam}
            />
            <div className="content">
                <div className="content-body">
                    <div className="container pt-4 pt-md-4">
                        <div className="row pb-4">
                            <div className="col">
                            <p>In a significant milestone, iTelaSoft has achieved the esteemed ISTQB Platinum Partnership status.</p>
                            
                            <p>This recognition, granted by the International Software Testing Qualifications Board (ISTQB<sup>®</sup>), places us amongst an exclusive group of organisations that demonstrate exceptional testing practices for our customers and actively contribute to the wider software testing community.</p>
                            
                            <p>iTelaSoft has been at the forefront of providing top-notch software quality assurance services for nearly a decade. We have a rigorous testing processes applied to the software products developed for customers, and we also extend our expertise by offering software testing as a service to numerous large organisations.</p>
                            
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>What is Platinum Partnership and how is it achieved?</h3>
                
                            <p>The ISTQB® is the leading global certification scheme in the field of software testing. This non-profit association aims to standardise and improve the field of software testing through exams and certifications.</p>

                            <p>Its Partner Program was established to recognise organisations with a demonstrated commitment to software testing certifications. Platinum is one of the highest tiers that a company can reach, requiring a significant amount of our QA team to hold ISTQB certifications at all levels. There are also requirements for the business to demonstrate testing proficiency & ongoing development.</p>

                            <p>Our QA team was able to juggle ongoing projects, deadlines, and stringent requirements as they successfully pursued this achievement. We’re incredibly proud of them for their time, dedication, and success in achieving this goal.</p>
                            
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>The advantages of ISTQB Partnership for our customers</h3>

                            <p>Attaining Platinum Partnership status solidifies iTelaSoft as a leader in the industry. This acknowledgment not only reflects our commitment to excellence but also acts as a promise to our customers.</p>

                            <p><b>The partnership provides our customers with:</b></p>

                            <ul>
                                <li>Superior product quality</li>
                                <li>Reduced risks</li>
                                <li>Faster time-to-market</li>
                                <li>Improved communication</li>
                                <li>Continuous improvement</li>
                                <li>Increased confidence</li>
                                <li>Access to specialised expertise</li>
                            </ul>

                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>A significant milestone that elevates iTelaSoft</h3>

                            <p>Achieving Platinum Partnership elevates us within the business community and improves professional outcomes. Our team now holds greater recognition of expertise, competitive advantage, enhanced customer confidence, access to global networks, and validation of investment in quality.</p>

                            <p>It serves as a powerful differentiator against our competitors, showcases our company’s high level of proficiency, and gives us a competitive edge when attracting customers and competing for business opportunities locally and internationally.</p> 

                            <p>This marks a pivotal moment in our journey, reaffirming our commitment to excellence, innovation, and leadership in the field of software quality assurance.</p> 

                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>Software solutions delivered by the experts</h3> 

                            <p>Since 2009, iTelaSoft has been solving complex business problems with cutting-edge technology solutions.</p> 

                            <p>If you’re looking for a reliable, trusted partner, iTelaSoft is the right choice. We specialise in strategy consultation, innovation incubation, solution design, development and management of products.</p>

                            <p className="link"><a href="/contact/">Contact us</a> today to chat with one of our specialists to see how we can help you achieve your goals with the right software solution.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query {
    WebComponents: file(relativePath: { eq: "webcomponents.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    UIControls: file(relativePath: { eq: "ui-controls.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SMSBot;
